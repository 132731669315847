import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types';
import {graphql} from 'gatsby'
import {Link} from 'gatsby'
import Img from "gatsby-image"
import {format, distanceInWords, differenceInDays} from 'date-fns'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from '../../lib/helpers'
import BlogPostPreviewList from '../../components/blog-post-preview-list'
import Container from '../../components/container'
import GraphQLErrorList from '../../components/graphql-error-list'
import SEO from '../../components/seo'
import Layout from '../../containers/layout'
import styled from 'styled-components'
import { ContainerFullWidth, ContainerMain, Backdrop, ContainerTwoCol, ContainerContent } from '../../containers'
import Hero from '../../components/hero'
import PortableText from '../../components/portableText'
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';
import NumberFormat from 'react-number-format';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend
} from 'recharts';
import { DiscussionEmbed } from 'disqus-react'
import { MdHelpOutline, MdHelp } from "react-icons/md";
import ReactTooltip from 'react-tooltip';
import { PricesContext } from '../../lib/prices';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
// import NativeSelect from '@material-ui/core/NativeSelect';
import fetch from 'isomorphic-unfetch';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { MdArrowForward } from "react-icons/md";
import Sidebar from '../../components/Sidebar'
import { GraphDesktop, GraphMobile, AxisLabel } from '../../components/GraphComponents'
import { ThemedAccordion, FinancesTableWrapper, FinancesTableGrid, FinancesTableRow } from '../../components/CustomAccordion'
import { BlockFiCardAdPanel, LedgerAdPanel } from '../../components/InlineAds'

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query USDCoinCalculatorPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
      metaImage {
        ...SanityImage
        alt
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
          fixed(width: 400) {
            ...GatsbySanityImageFixed
          }
        }
      }
    }
    posts: allSanityPost(
      limit: 6
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          authors{
            author{
              name
              id
            }
          }
          mainImage {
            asset{
              fluid {
                ...GatsbySanityImageFluid
              }
              fixed(width: 400) {
                ...GatsbySanityImageFixed
              }
            }
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`

const H2 = styled.h2`
  font-weight: 300;
`

// const CalculatorGrid = styled(ContainerMain)`
//   box-sizing: border-box;
//   border: 1px solid ${ props => props.theme.theme.border.secondary};
//   background-color: ${props => props.theme.theme.bg.primary};
//   box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.05);
//   border-radius: 8px;
//   display: grid;
//   grid-template-columns: minmax(300px, 1fr) 3fr;
//   grid-gap: 48px;
//   align-items: start;
//   margin-bottom: 48px;

//   @media (max-width: 500px) {
//     grid-template-columns: 1fr;
//     padding: 24px 24px 0 24px;
//     border: none;
//     border-radius: 0px;
//     margin: 0;
//   }

//   p, ul {
//     font-size: 0.9rem;
//     font-family: 'Inter', sans-serif;
//   }
// `

const CalculatorPanel = styled.div`
  margin: 0 auto;
  /* padding: 24px; */
  box-sizing: border-box;
  border: 1px solid ${ props => props.theme.theme.border.secondary};
  background-color: ${props => props.theme.theme.bg.primary};
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.05);
  border-radius: 8px;
  max-width: 1200px;

  @media (max-width: 500px) {
    border: none;
    border-radius: 0px;
  }
`

const CalculatorGrid = styled.div`
  padding: 24px;
  /* margin: 0 auto;
  box-sizing: border-box;
  border: 1px solid ${ props => props.theme.theme.border.secondary};
  background-color: ${props => props.theme.theme.bg.primary};
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.05);
  border-radius: 8px; */
  display: grid;
  grid-template-columns: minmax(300px, 1fr) 3fr;
  grid-gap: 48px;
  align-items: start;
  margin-bottom: 0px;
  /* max-width: 1200px; */

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    padding: 24px 24px 0 24px;
    border: none;
    border-radius: 0px;
    margin: 0;
  }

  p, ul {
    font-size: 0.9rem;
    font-family: 'Inter', sans-serif;
  }
`

const ThemedTextarea = styled(TextField)`
  .MuiFormLabel-root {
    color: ${props => props.theme.theme.text.primary};
  }

  &.MuiFormControl-root {
    margin: 0 0 24px 0;
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${props => props.theme.theme.colors.green};
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.theme.colors.green};
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline   {
    border-color: ${props => props.theme.theme.formBorder.primary};
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline   {
    border-color: ${props => props.theme.theme.colors.green};
  }

  .MuiInputBase-input {
    color: ${props => props.theme.theme.text.primary};
  }
`

const ThemedTextareaPrimary = styled(ThemedTextarea)`
  &.MuiFormControl-root {
    margin: 24px 0;
  }

  &:hover{
    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: ${props => props.theme.theme.colors.green};
    }
  }

  .MuiFormLabel-root {
    color: ${props => props.theme.theme.text.secondary};
    font-weight: 500;
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.theme.colors.green};
  }
`

const ThemedSelectField = styled(FormControl)`
  .MuiFormLabel-root {
    color: ${props => props.theme.theme.text.primary};
  }

  &.MuiFormControl-root {
    margin: 0 0 24px 0;
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${props => props.theme.theme.colors.green};
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.theme.colors.green};
  }
`

const ThemedSlider = styled(Slider)`
  &.MuiSlider-root {
    color: ${props => props.theme.theme.colors.green};
  }
`

const Label = styled.h3`
  margin: 4px 0 0 0;
  font-size: 0.9rem;
  font-weight: 400;
  display: flex;

  svg {
    margin: 0 0 0 8px;
    height: 1.4rem;
  }
`


const CardText300 = styled.h3`
  margin: 0;
  font-size: 0.9rem;
`

const CardText400 = styled.h4`
  margin: 0;
  font-size: 0.9rem;
  font-weight: 400;
`

const TooltipPanel = styled.div`
  background-color: ${props => props.theme.theme.bg.secondary};
  box-shadow: 0 1px 2px 2px rgba(0,0,0,0.1);
  padding: 10px 10px;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr;
`

const SliderGrid = styled.div`
  box-sizing: border-box;
  border: 0px solid ${ props => props.theme.theme.border.secondary};
  border-radius: 4px;
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-gap: 16px;
  align-items: start;
`

const InitialInvestmentBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr 150px;
  grid-gap: 16px;
`

const AllocationGroup = styled.div`
  border-radius: 8px;
  padding: 8px 12px 12px 12px;
  margin: 0 0px 24px 0;

  h3 {
    text-transform: uppercase;
    margin: 0 0 8px 0;
    font-weight: 500;
  }
`;

const ResultsPanel = styled(AllocationGroup)`
  background-color: ${(props) => props.theme.theme.colors.transparentGreen};
  border: 1px solid ${(props) => props.theme.theme.colors.green};
  padding-bottom: 12px;
  display: grid;
  /* flex-wrap: wrap; */
  grid-gap: 8px;

  .label {
    font-size: 0.9rem;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0 8px 0 0;
  }

  .result-value {
    background-color: rgba(0, 0, 0, 0.09);
    padding: 4px 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    margin: 0 0px 0 0;
  }

  h3 {
    color: ${(props) => props.theme.theme.colors.green};
  }

  .equals {
    padding: 0 12px;
  }

  @media (max-width: 500px) {
    /* margin: 0 12px; */
  }
`;

const ResultsGroup = styled.div`
  display: grid;
  align-items: center;
  padding: 4px 0;
  flex-wrap: wrap;
  justify-items: left;

  @media (max-width: 500px) {
  }
`;

const ResultsGroupTwoCol = styled.div`
  display: grid;
`

const InterestTable = styled.div`
  display: grid;
  margin: 0 auto 0 0;
  width: 300px;
  border: 1px solid ${(props) => props.theme.theme.border.secondary};
  padding: 4px;
  background-color: ${props => props.theme.theme.bg.primary};
`

const InterestTableRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: left;
  padding: 4px 8px;
  grid-gap: 16px;
  border-top: 1px solid ${(props) => props.theme.theme.border.secondary};

  &.labels-row {
    font-weight: 600;
    border: none;
  }
`

const ActionButton = styled.button`
  background-color: ${(props) => props.theme.theme.colors.blue};
  border: none;
  color: ${(props) => props.theme.theme.colors.white};
  /* border: 1px solid ${(props) => props.theme.theme.colors.green}; */
  padding: 10px 12px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;

  svg {
    padding: 0 0 0 8px;
  }

  &:hover{
    cursor: pointer;
    /* background-color: ${(props) => props.theme.theme.colors.transparentGreenHover}; */
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.1);
  }
`

const InterestRatePanel = styled.div`
  background-color: ${(props) => props.theme.theme.colors.transparentBlue};
  border: 1px solid ${(props) => props.theme.theme.colors.blue};
  color: ${(props) => props.theme.theme.colors.blue};
  /* border: 1px solid ${(props) => props.theme.theme.colors.green}; */
  padding: 10px 12px;
  display: inline-block;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  margin: 12px 0 0 0;
  width: auto;

  svg {
    padding: 0 0 0 8px;
  }
`

const CustomTooltip = (props) => {
  const {active} = props

  if(active){
    const {payload, label } = props
    console.log(payload)
    console.log(label)

    return(
      <TooltipPanel>
        {/* <CardText300>{label}</CardText300> */}
        <CardText400>
          <strong>Month: </strong>
          {payload[0].payload.month}</CardText400>
        <CardText400>
          <strong>Future Value (USDC): </strong>
          <NumberFormat
              displayType={'text'}
              value={payload[0].payload.localCryptoFutureValue.toFixed(2)}
              thousandSeparator
          />
        </CardText400>

        <CardText400>
          <strong>Monthly interest earned (USDC): </strong>
          <NumberFormat
              displayType={'text'}
              value={payload[0].payload.interestCrypto.toFixed(2)}
              thousandSeparator
          />
        </CardText400>

      </TooltipPanel>
    )
  }
  return(null)

}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      // prefix="$"
    />
  );
}

function NumberFormatCrypto(props) {
  const { inputRef, onChange, ticker, ...other } = props;

  console.log(props)

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      suffix={` USDC`}
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function NumberFormatPercentage(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      suffix="%"
    />
  );
}

NumberFormatPercentage.propTypes = {
  inputRef: PropTypes.func.isRequired,
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const USDCoinCalculator = props => {
  const assetOptions = [
    {
      name: 'BTC ( 0 to 1)',
      interest: 6,
      ticker: 'BTC',
      id: 'bitcoin'
    },
    {
      name: 'BTC (> 1.0 to 20)',
      interest: 2,
      ticker: 'BTC',
      id: 'bitcoin'
    },
    {
      name: 'BTC (> 20)',
      interest: 0.5,
      ticker: 'BTC',
      id: 'bitcoin'
    },
    {
      name: 'LINK',
      interest: 5.5,
      ticker: 'LINK',
      id: 'chainlink'
    },
    {
      name: 'ETH (0 to 100)',
      interest: 5.25,
      ticker: 'ETH',
      id: 'ethereum'
    },
    {
      name: 'ETH (>100 to 1000)',
      interest: 2,
      ticker: 'ETH',
      id: 'ethereum'
    },
    {
      name: 'ETH (>1000)',
      interest: 0.5,
      ticker: 'ETH',
      id: 'ethereum'
    },
    {
      name: 'LTC',
      interest: 6.5,
      ticker: 'LTC',
      id: 'litecoin'
    },
    {
      name: 'USDC',
      interest: 7.5,
      ticker: 'USDC',
      id: 'usd-coin'
    },
    {
      name: 'GUSD',
      interest: 8.6,
      ticker: 'GUSD',
      id: 'gemini-dollar'
    },
    {
      name: 'PAX',
      interest: 8.6,
      ticker: 'PAX',
      id: 'paxos-standard'
    },
    {
      name: 'PAXG',
      interest: 5,
      ticker: 'PAXG',
      id: 'pax-gold'
    },
    {
      name: 'USDT',
      interest: 9.3,
      ticker: 'USDT',
      id: 'tether'
    },
    {
      name: 'BUSD',
      interest: 8.6,
      ticker: 'BUSD',
      id: 'binance-usd'
    }
  ]





  const [ timeLength, setTimeLength ] = useState(5)
  const [ principal, setPrincipal ] = useState(1000)
  const [ payment, setPayment ] = useState(0)
  const [ annualCompoundingPeriods, setAnnualCompoundingPeriods ] = useState(12)
  const [ annualPaymentPeriods, setAnnualPaymentPeriods ] = useState(12)
  const [ investmentRate, setInvestmentRate ] = useState(7.5)


  // APR = ( (APY+1)^(1/n)-1 ) * n
  const APR = ( Math.pow( 1 + (investmentRate/100), (1/annualCompoundingPeriods) ) - 1 ) * annualCompoundingPeriods
  console.log(APR)

  const rate = (Math.pow( 1 + (APR/annualCompoundingPeriods), annualCompoundingPeriods/annualPaymentPeriods )) - 1
  const nper = annualPaymentPeriods * timeLength
  const futureValueCrypto = (principal) * Math.pow(1+rate, nper) + payment * ( (Math.pow(1+rate, nper)-1)/ rate )

  const calcCompoundingData = () => {
    let compoundingData = []
    for(let i = 0; i <= timeLength * annualPaymentPeriods; i++){
      const localNper = i
      const localCryptoFutureValue = (principal) * Math.pow(1+rate, localNper) + payment * ( (Math.pow(1+rate, localNper)-1)/ rate )

      compoundingData.push({
        month: i,
        localCryptoFutureValue: localCryptoFutureValue,
        interestCrypto: i ? localCryptoFutureValue - compoundingData[i-1].localCryptoFutureValue - payment : 0
      })
    }
    return compoundingData
  }

  const compoundingData = calcCompoundingData()

  console.log(compoundingData)
  console.log(principal)

  return(
    <>
      <ReactTooltip />
      <ContainerMain>
        <h1>USD Coin (USDC) Interest Calculator</h1>
        <H2>How much interest can you earn by depositing your USD Coin in an interest-bearing account?</H2>
      </ContainerMain>

      <CalculatorPanel>

      <CalculatorGrid>
        <form>
          <InitialInvestmentBlock>
            <ThemedTextarea
              id="outlined-required"
              label="Initial Investment"
              value={principal}
              onChange={event => setPrincipal(event.target.value)}
              variant="outlined"
              fullWidth
              InputProps={{
                inputComponent: NumberFormatCrypto,
              }}
            />
            <ThemedTextarea
              id="outlined-required"
              label="Interest Rate (APY)"
              value={investmentRate}
              onChange={event => setInvestmentRate(event.target.value)}
              variant="outlined"
              fullWidth
              InputProps={{
                inputComponent: NumberFormatPercentage
              }}
            />
          </InitialInvestmentBlock>

          {/* <ResultsPanel>
            <ResultsGroup>
              <span className="label">Current {selectedCurrency[0].ticker} price:</span>
              <span className="result-value">
              { selectedAltCurrencyData[0] && <NumberFormat
                  displayType={"text"}
                  value={parseFloat(selectedAltCurrencyData[0][1].usd).toFixed(2)}
                  thousandSeparator
                  prefix="$"
                /> }
              </span>
            </ResultsGroup>
            <ResultsGroup>
              <span className="label">Initial value (USD):</span>
              <span className="result-value">
              { selectedAltCurrencyData[0] && <NumberFormat
                  displayType={"text"}
                  value={(parseFloat(selectedAltCurrencyData[0][1].usd)*principal).toFixed(2)}
                  thousandSeparator
                  prefix="$"
                /> }
              </span>
            </ResultsGroup>
            <ResultsGroup>
              <span className="label">BlockFi interest rate:</span>
              <span className="result-value">
               <NumberFormat
                  displayType={"text"}
                  value={selectedCurrency[0].interest.toFixed(2)}
                  suffix="%"
                  isNumericString
                />
              </span>
            </ResultsGroup>
          </ResultsPanel> */}


          <ThemedTextarea
            id="outlined-required"
            label="Length of time in years"
            value={timeLength}
            onChange={event => setTimeLength(event.target.value)}
            variant="outlined"
            fullWidth
          />

          <ThemedTextarea
              id="outlined-required"
              label="Recurring Monthly Deposit"
              value={payment}
              onChange={event => setPayment(event.target.value)}
              variant="outlined"
              ticker={'btc'}
              suffix={`USDC`}
              fullWidth
              InputProps={{
                inputComponent: NumberFormatCrypto,
                ticker: 'btc'
              }}
            />
          {/* <ThemedTextarea
            id="outlined-name"
            label="Monthly contribution"
            value={payment}
            onChange={event => setPayment(event.target.value)}
            variant="outlined"
            fullWidth
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
          /> */}

        {/* <SliderGrid>

          <ThemedSlider
            value={investmentRate}
            aria-labelledby="discrete-slider-always"
            step={0.25}
            min={0}
            max={20}
            marks={[
              {
                value: 0,
                label: '0%'
              },
              {
                value: 7,
                label: '7%'
              },
              {
                value: 20,
                label: '20%'
              }
            ]}
            valueLabelDisplay="auto"
            onChange={ (event, value) => setInvestmentRate(value)}
          />

          <ThemedTextarea
            id="outlined-name"
            label="Rate"
            value={investmentRate}
            onChange={event => setInvestmentRate(event.target.value)}
            variant="outlined"
            InputProps={{
              inputComponent: NumberFormatPercentage,
            }}
            fullWidth
          />
        </SliderGrid> */}

        <ResultsPanel>
          <ResultsGroup>
            <span className="label">End value:</span>
            <span className="result-value">
            { futureValueCrypto && <NumberFormat
                displayType={"text"}
                value={futureValueCrypto.toFixed(2)}
                thousandSeparator
                suffix={` USDC`}
              /> }
            </span>
          </ResultsGroup>

          <ResultsGroup>
            <span className="label">Initial monthly earning:</span>
            <span className="result-value">
            <NumberFormat
                displayType={"text"}
                value={(principal*(investmentRate/(100*12))).toFixed(2)}
                thousandSeparator
                suffix={` USDC`}
              />
            </span>
          </ResultsGroup>

          <ResultsGroup>
            <span className="label">Average earning per year:</span>
            <span className="result-value">
            { futureValueCrypto && <NumberFormat
                displayType={"text"}
                value={ ((futureValueCrypto - principal - (payment*timeLength*12))/timeLength).toFixed(2) }
                thousandSeparator
                suffix={` USDC`}
              /> }
            </span>
          </ResultsGroup>

          <ResultsGroup>
            <span className="label">Overall earning:</span>
            <span className="result-value">
            { futureValueCrypto && <NumberFormat
                displayType={"text"}
                value={ (futureValueCrypto - principal - (payment*timeLength*12)).toFixed(2) }
                thousandSeparator
                suffix={` USDC`}
              /> }
            </span>
          </ResultsGroup>
        </ResultsPanel>

          <a href="https://blockfi.mxuy67.net/walletburst" target="_blank">
            <ActionButton>
              Open a BlockFi USDC Account
              <MdArrowForward size="20px" />
            </ActionButton>
          </a>

          <InterestRatePanel>
            Current BlockFi USDC Interest Rate: {investmentRate}% APY
          </InterestRatePanel>

          <p>Earn up to $250 in bitcoin when you use the link above to open a new BlockFi Interest Account and deposit funds.</p>

          {/* <ThemedTextareaPrimary
            id="filled-read-only-input"
            label="Investment Value"
            value={futureValue.toFixed(0)}
            variant="outlined"
            fullWidth
            InputProps={{
              inputComponent: NumberFormatCustom,
              readOnly: true
            }}
          /> */}

        </form>

          <GraphDesktop>
            <ResponsiveContainer width='100%' height={500} >
              <AreaChart data={compoundingData}
                  margin={{top: 30, right: 50, left: 50, bottom: 30}}>
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="month" label={{value: "Time (months)", position: "insideBottom", offset: -10}} />
                <YAxis
                  tickFormatter={tick => {
                    return `$${tick.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                  }}
                  yAxisId="left"

                />
                <YAxis
                  tickFormatter={tick => {
                    return `${tick.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} USDC`
                  }}
                  yAxisId="right"
                  orientation="right"
                />
                {/* <Tooltip/> */}
                <Tooltip
                  content={<CustomTooltip/>}
                />
                <Area type='monotone' name="Investment Value ($)" dataKey='localCryptoFutureValue' stroke='#3AC732' fill='#CFF0CD' yAxisId="left"/>
              </AreaChart>
            </ResponsiveContainer>
          </GraphDesktop>

          <GraphMobile>
            <ResponsiveContainer width='100%' height={500} >
              <AreaChart data={compoundingData}
                  margin={{top: 10, right: 0, left: 0, bottom: 30}}>
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="month" label={{value: "Time (months)", position: "insideBottom", offset: -10}} />
                <YAxis
                  tickFormatter={tick => {
                    return `$${tick.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                  }}
                  yAxisId="left"
                  mirror
                />
                <YAxis
                  tickFormatter={tick => {
                    return `${tick.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} USDC`
                  }}
                  yAxisId="right"
                  orientation="right"
                  mirror
                />
                {/* <Tooltip/> */}
                <Tooltip
                  content={<CustomTooltip/>}
                />
                {/* <Legend verticalAlign="top" height={36} /> */}
                <Area type='monotone' name="Investment Value ($)" dataKey='localCryptoFutureValue' stroke='#3AC732' fill='#CFF0CD' yAxisId="left"/>
              </AreaChart>
            </ResponsiveContainer>
          </GraphMobile>
      </CalculatorGrid>

        <ThemedAccordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>View Monthly Interest Payment Schedule</AccordionSummary>
          <AccordionDetails>
            <FinancesTableWrapper>
              <FinancesTableGrid>
                <FinancesTableRow className="labels-row">
                  <span>Month #</span>
                  <span>Account Value (USDC)</span>
                  <span>Monthly interest earned (USDC)</span>
                </FinancesTableRow>

                {compoundingData.map((dataPoint, index) => {
                  return(
                    <FinancesTableRow>
                        <span>{dataPoint.month}</span>
                        <span>
                          <NumberFormat
                            displayType={"text"}
                            value={dataPoint.localCryptoFutureValue.toFixed(2)}
                            thousandSeparator
                          />
                        </span>
                        <span>
                          <NumberFormat
                            displayType={"text"}
                            value={dataPoint.interestCrypto.toFixed(2)}
                            thousandSeparator
                          />
                        </span>
                      </FinancesTableRow>
                  )
                })}
              </FinancesTableGrid>
            </FinancesTableWrapper>
          </AccordionDetails>
        </ThemedAccordion>

      </CalculatorPanel>

      <ContainerTwoCol>
        <ContainerContent>

        <p>
          Remember when you used to be able to earn several percentage points of interest on money parked in your
          savings or checking accounts? With interest rates near all time lows, the days of earning any meaningful
          amount of interest in your traditional savings accounts are long gone.
        </p>
        <p>
          On the bright side, many crypto-lending platforms like BlockFi offer a compelling alternative with accounts that allow you to
          earn meaningful interest rates on your USD Coin (USDC) deposits.
        </p>

        <h2>What is USD Coin (USDC)?</h2>
        <p>
          USD Coin (USDC) is a type of cryptocurrency called a stable coin. USDC is issued by <a href="https://www.circle.com/en/usdc" target="_blank">Circle</a> and <a href="https://www.coinbase.com/usdc" target="_blank">Coinbase</a>, two leading
          regulated financial institutions, and is fully backed by reserve assets. The price of 1 USDC is pegged to $1 (US dollar),
          meaning that each USDC is redeemable 1:1 for US Dollars. USDC is widely considered to be the gold standard in USD-pegged
          stable coins and is effectively a digital dollar that allows global transactions denominated in USD between
          crypto-wallets. USD Coin is an Ethereum token powered by the Ethereum network, meaning that it can be stored in any
          Ethereum-based wallet.
        </p>

        <LedgerAdPanel />

        <h2>How do I earn interest on my USDC?</h2>
        <p>
          There are multiple ways that you can earn interest on your USD Coin that offer varying returns depending on your risk
          appetite. On the low-risk end, <a href="https://www.coinbase.com/join/549f3ef5e81d8e7f99000004">Coinbase</a> offers a 0.15% interest rate on your USDC deposits, acting effectively as a
          savings account for your USDC. If you want to earn meaningful returns on your USDC however, then crypto lending
          platforms offer interest rates in the high single digits, but these do come with some risk.
        </p>

        <p>
          Here at WalletBurst, I use and recommend BlockFi as my crypto-lending platform of choice for earning interest on my USDC.
          You can read more about my experience with depositing USDC at BlockFi below.
        </p>

        <h2>About BlockFi</h2>
        <p>
          BlockFi is a privately-held cryptocurrency financial services platform that was founded in 2017 and is
          based in New York. BlockFi's Interest Account product allows users to deposit various crypto tokens like
          bitcoin, ethereum, and stablecoins like USDC which earn interest on these deposits. They then use these funds
          to provide crypto-backed loans to trusted institutional and corporate borrowers.
        </p>

        <h2>Get started with BlockFi</h2>
        <a href="https://blockfi.mxuy67.net/walletburst" target="_blank">
          <ActionButton>
            Open a BlockFi USDC Account
            <MdArrowForward size="20px" />
          </ActionButton>
        </a>

        <InterestRatePanel>
          Current BlockFi USDC Interest Rate: {investmentRate}% APY
        </InterestRatePanel>

        <p>
          Signing up for an account with BlockFi is easy and can be done in about 2
          minutes. Use this sign-up link above to get a bonus of up to $250 when you open a new BlockFi Interest Account.
          After opening this link, simply click "Sign Up" to open your account. Depending on how much you deposit in your account, you could earn up to $250 worth of bitcoin.
          Once you've opened your account, there are several ways you can add funds to your account:
        </p>
        <ul>
          <li>
            Via ACH transfer, which deposits USD directly to purchase crypto
          </li>
          <li>
            Via wire transfer, which deposits USD directly to purchase crypto
          </li>
          <li>
            Deposit crypto from any external platform or wallet by sending it to BlockFi's wallet address for the specific currency
          </li>
        </ul>

        <BlockFiCardAdPanel />

        <h2>How I use BlockFi</h2>
        <p>
          After doing my research and comparing all the crypto interest platforms out there, I decided to go with BlockFi a few months ago and my experience has been excellent.
          I currently have a few thousand USDC deposited with BlockFi earning a very attractive 8.6% interest. The high interest rates for stablecoins like USDC and GUSD make this
          the most attractive use case for me. BlockFi also allows you to choose which currency your interest is paid in (it can be any of the ones in the table above), which is a
          cool feature. I chose to recieve interest payments in bitcoin.
        </p>

        <h2>BlockFi Reputation and Risks</h2>
        <p>
          It's important to do your own research and due diligence before depositing your funds with BlockFi.
          A key thing to understand is that unlike traditional banks, your deposits at BlockFi are not FDIC insured.
          This means that, in the unlikely event that BlockFi's creditors were unable to pay back their loans, your
          deposit could be lost, with no backstopping from the Federal Reserve. With this in mind, BlockFi has several
          lines of defense in place to mitigate the risk to your deposits. Lyn Alden does an excellent job of covering
          these in <a href="https://www.lynalden.com/blockfi/" target="_blank">her review of BlockFi</a>.
          To summarize, these lines of defense include:
        </p>
        <ul>
          <li>Many of BlockFi's loans are collateralized by 2x or more of the loan value.</li>
          <li>
            When it comes to working with institutional borrowers, BlockFi focuses on making loans
            to large creditworthy firms where their BlockFi loan is only a very small portion of their operation.
          </li>
          <li>
            BlockFi spreads out their loans and avoids having too much exposure to one single institutional customer. This reduces
            the damage that could be done by any individual borrower failure.
          </li>
          <li>
            Lastly, and perhaps the most important to me as a BlockFi customer, is that depositors are higher in the
            liquidation stack than even equity holders in the company. This means in the event of a solvency breakdown,
            the shareholders and investors in BlockFi would lose their investment in order to return funds to depositors.
            In essence, the entire company itself is on the line in the event that deposits were lost, which aligns
            BlockFi's incentive to mitigate risk with the continued existence of the company.
          </li>
        </ul>

        <p>
          As far as reputation goes,  BlockFi is widely considered to be the most reputable player in the crypto-lending
          space for several reasons. They are the largest player in the space and have institutional backing from investors
          including Valar Ventures, Galaxy Digital, Fidelity, Akuna Capital, SoFi and Coinbase Ventures. BlockFi's primary
          crypto custodian is Gemini Trust Company, LLC, a New York trust company regulated by the New York State Department
          of Financial Services. This is the same Gemini crypto exchange started by Tyler and Cameron Winklevoss, which has
          institutional-grade security and is widely considered one of the most secure custodians in the crypto space.
          Additionally, BlockFi is domiciled in the United States and has built a team of professionals with decades of
          FinTech experience.
        </p>

        <h2>Learn more about BlockFi</h2>
        <ul>
          <li><a href="https://www.lynalden.com/blockfi/" target="_blank">BlockFi Review and Risk Analysis by Lyn Alden</a></li>
          <li><a href="https://www.realvision.com/blog/earning-on-your-crypto" target="_blank">Real Vision podcast with Zac Prince, Founder and CEO of BlockFi</a></li>
        </ul>

        </ContainerContent>

        <Sidebar />
      </ContainerTwoCol>
    </>
  )
}

const BlockFiCalculatorWrapper = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const posts = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
    : []

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <Layout>
      <SEO
        title={'USD Coin (USDC) Interest Calculator'}
        description={'Calculate how much interest you can earn on your USD Coin (USDC) cryptocurrency'}
        keywords={site.keywords}
      />
      <USDCoinCalculator />

      <Backdrop>
        <ContainerMain>
          <DiscussionEmbed
              shortname={process.env.GATSBY_DISQUS_NAME}
              config={{
                  identifier: 'usdc-calculator'
              }}
          />
        </ContainerMain>
      </Backdrop>
    </Layout>
  )
}

export default BlockFiCalculatorWrapper
